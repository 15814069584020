import { graphql } from "gatsby"
import React from "react"
import {
  Appear,
  LetsTalk,
  LocaleSwitcher,
  PressLinks,
  RelatedCaseStudies,
  Section,
  SEO,
} from "../components"
import { CaseStudy } from "../models/CaseStudy"
import { Page } from "../models/Page"
import { Locale } from "../models/Locale"

interface Props {
  data: {
    caseStudy: CaseStudy
  }
}

const CaseStudyPage = (props: Props & Page) => {
  const { caseStudy } = props.data
  return (
    <>
      <SEO seoMetaTags={caseStudy.seoMetaTags} />
      <article>
        <header className="container">
          <div className="flex flex-wrap pb-4 md:pb-6 lg:-mx-3">
            <div className="w-full lg:w-1/4 lg:px-3">
              <Appear order={1} threshold={1}>
                <h1 className="mb-2 heading-m lg:heading-l">{caseStudy.client}</h1>
              </Appear>
            </div>
            <div className="md:w-3/4 lg:w-1/2 lg:px-3">
              <Appear order={2} threshold={1}>
                <p className="heading-m lg:heading-l font-normal">{caseStudy.synopsis}</p>
                <LocaleSwitcher
                  locales={[Locale.English, Locale.Chinese]}
                  path={`/work/${caseStudy.slug}`}
                />
                {/* {caseStudy.tags && caseStudy.tags.length > 0 && (
                  <div className="w-full pt-6 heading-s">
                    {caseStudy.tags.join(", ")}
                  </div>
                )} */}
              </Appear>
            </div>
          </div>
        </header>

        {caseStudy.content &&
          caseStudy.content.map((section, i) => {
            return <Section key={section.id} section={section} order={i} />
          })}

        {caseStudy.pressLinks && caseStudy.pressLinks.length > 0 && (
          <div className="pb-16 lg:pb-20">
            <PressLinks links={caseStudy.pressLinks} />
          </div>
        )}
      </article>

      <RelatedCaseStudies items={caseStudy.related} />

      <LetsTalk />
    </>
  )
}

export const query = graphql`
  query CaseStudyQuery($slug: String!, $locale: String!) {
    caseStudy: datoCmsCaseStudy(slug: { eq: $slug }, locale: { eq: $locale }) {
      locales: _allContentLocales {
        locale
      }
      client
      slug
      synopsis
      tags
      title
      content {
        __typename
        ...AnimatedGifFragment
        ...HeroGalleryFragment
        ...ImageFragment
        ...ImageGalleryLargeFragment
        ...QuoteFragment
        ...TextFragment
        ...VideoFragment
        ...Text2colFragment
      }
      pressLinks {
        ...PressLinkFragment
      }
      related {
        id
        client
        title
        slug
        thumbnail {
          gatsbyImageData(width: 616, imgixParams: { auto: "format" })
        }
        thumbnailVideo {
          ...VideoFileFragment
        }
      }
      seoMetaTags {
        tags
      }
    }
  }
`

export default CaseStudyPage
